@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap");
@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css');

* {
  font-family: "Poppins", sans-serif;
}

:root {
  --vtg-color: #a22a26;
  --vtg-color-dark: #6D0000;
  --vtg-color-light: #c96565;
  --lightgray: #e0e0e0;
  --skeleton-color: #9c9c9c;
  --corner-separation: 0.4rem;
  --ss-text-active: #006644;
  --ss-background-active: #E3FCEF;
  --ss-text-inactive: #b18a29;
  --ss-background-inactive: #FCF4E3;
  --ss-text-expired: #661400;
  --ss-background-expired: #FCE6E3;
  --ss-text-paused: #434343;
  --ss-background-paused: #F3F3F3;
}

.fs-xs {
  font-size: 10px;
}

.fs-md {
  font-size: 14px;
}

.fs-lg {
  font-size: 18px;
}

.fs-xl {
  font-size: 20px;
}

.fs-xxl {
  font-size: 28px;
}

.without-border:focus{
  outline: none !important;
}

.full-container {
  height: 100vh;
  width: 100%;
  margin: 0 !important;
}

.hover:hover {
  background-color: #F2F2F2;
}

.line-0 {
  line-height: 0 !important;
}

.w-fit {
  width: fit-content !important;
}

.nav-item.nav-link:not(.active) {
  color: #1d1d1d;
}

.nav-item.nav-link.active {
  color: var(--vtg-color) !important;
}

.nav-item.nav-link {
  font-weight: 600;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9b9b9b !important;
  font-style: italic;
  opacity: 1; /* Firefox */
}

.dropdown-toggle.without-caret:after {
  content: none !important;
}

.dropdown-item {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.dropdown-item.info {
  color: var(--vtg-color) !important;
  padding-left: 0 !important;
  padding-right: 1rem !important;
}

.dropdown-item.info:focus, .dropdown-item.info:hover, .dropdown-item.info:active {
  color: var(--vtg-color) !important;
  text-decoration: none !important;
  background-color: transparent !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #9b9b9b !important;
  font-style: italic;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #9b9b9b !important;
  font-style: italic;
}

.bg-vitag {
  background-color: var(--vtg-color) !important;
}

.text-vitag {
  color: var(--vtg-color) !important;
}

.bg-lightgray {
  background-color: var(--lightgray) !important;
}

.profile-tab-active {
  font-weight: bold;
}

.profile-tab {
  font-size: 18px;
  max-width: 200px;
  cursor: pointer;
}

.for-chrome .vjs-big-play-button {
  line-height: 1.5em !important;
}

.color-preview {
  width: 14px !important;
  height: 14px !important;
  border: solid 1px #ededed;
}

.small {
  font-size: smaller;
}

.alert {
  border-left-width: 12px !important;
}

.video-select-indicator {
  position: absolute;
  top: 0.8rem;
  left: 0.8rem;
  -ms-transform: scale(1.6); /* IE */
  -moz-transform: scale(1.6); /* FF */
  -webkit-transform: scale(1.6); /* Safari and Chrome */
  -o-transform: scale(1.6); /* Opera */
  transform: scale(1.6);
}

.circle-mini-badge .material-icons-round {
  font-size: 14px;
}

.circle-mini-badge {
  width:24px;
  height:24px;
  border-radius:50%;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border: solid 1px #ddd;
}

.border-vitag-lg {
  border: solid 4px var(--vtg-color);
}

.vitag-shadow {
  box-shadow: 0px 0px 0px 8px rgb(162, 42, 38);
}

.vitag-shadow.with-blink {
  animation: blink 1.2s ease 1;
}

@keyframes blink {
  0% {
    box-shadow: 0px 0px 0px 8px transparent;
  }
  100% {
    box-shadow: 0px 0px 0px 8px rgb(162, 42, 38);
  }
}

.vitag-text {
  color: var(--vtg-color) !important;
}

.vitag-text-dark {
  color: var(--vtg-color-dark) !important;
}

.vitag-text-light {
  color: #fdcc76 !important;
}

.vitag-text-expired {
  font-style: italic;
  background-color: #F3F3F3 !important;
}

.home-container {
  min-height:460px;
  align-content:flex-start;
}

.corner-tr {
  position: absolute;
  top: var(--corner-separation);
  right: var(--corner-separation);
}

.corner-tl {
  position: absolute;
  top: var(--corner-separation);
  left: var(--corner-separation);
}

.corner-br {
  position: absolute;
  bottom: var(--corner-separation);
  right: var(--corner-separation);
}

.corner-bl {
  position: absolute;
  bottom: var(--corner-separation);
  left: var(--corner-separation);
}

.btn.bg-vitag:not(.btn-outline-light) {
  border-color: var(--vtg-color-dark) !important;
}

.btn.btn-vitag {
  background-color: var(--vtg-color) !important;
  color: white !important;
}

.btn.btn-vitag:hover {
  background-color: var(--vtg-color-dark) !important;
  color: #ededed !important;
}

.btn {
  text-transform: uppercase;
  letter-spacing: 0.14em;
  /*font-weight: 600;*/
}

.drag-and-drop-video {
  border: 2px dashed #cacaca;
  border-radius: 10px;
}
.drag-and-drop-video span {
  color: #e18380;
}
.drag-and-drop-video p {
  color: #3d3d3d;
}

.url-video-source {
  background-color: #F2F2F2;
  width: calc(100% + 32px);
  color: #636363;
  margin-top: 32px;
  margin-bottom: -24px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.url-video-source div {
  border: 1px solid #B5B5B5;
  border-radius: 4px;
  background-color: white;
}
.url-video-source div img {
  width: 16px;
}

.profile-top-settings {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 32px;
  right: 32px;
  border-radius: 100px;
  padding: 8px;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(54, 14, 13, 0.5);
  color: var(--vtg-color-dark);
}
.profile-top-settings img {
  border-radius: 100px;
  width: 40px;
  background-color: #F9F9F9;
}

.video-preview {
  cursor: pointer;
}
.video-preview:hover h2 {
  color: var(--vtg-color);
}
.video-preview h2 {
  width: 100%;
  min-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.video-preview-img {
  height:160px;
  object-fit: contain;
  background-color: #000;
  object-position: center;
}

.video-preview-container {
  position: relative;
}
.video-preview-container img {
  width: 100%;
}
.video-preview-container .remove-video {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 12px;
  border-radius: 100px;
  position: absolute;
  right: -12px;
  top: -12px;
  background-color: var(--vtg-color);
}
.video-player-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: black;
  position: relative;
}
.burger-container {
  background-color: var(--vtg-color);
  color: #d9d9d9;
  position: absolute;
  right: 10vw;
  top: 0;
  width: 46px;
  height: 46px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 555;
  transform: translateY(-100%);
  transition: 0.2s ease-in;
}
.burger-container.active {
  transform: translateY(0) !important;
  transition: 0.2s ease-in-out;
}
.burger-container.active .burger-trigger {
  width: 46px;
  height: 46px;
  cursor: pointer;
  z-index: 6;
  position: relative;
  align-content: bottom !important; 
}
.burger-container .burger-trigger span {
  position: absolute;
  left: calc(14px / 2);
  bottom: 5px;
}

@keyframes watermarkMove {
  0% {
    transform: translateX(5vh) translateY(50vh);
  }
  25% {
    transform: translateX(50vw) translateY(10vh);
  }
  50% {
    transform: translateX(80vw) translateY(50vh);
  }
  75% {
    transform: translateX(50vw) translateY(90vh);
  }
  100% {
    transform: translateX(5vh) translateY(50vh);
  }
}

#vtg-water-mark {
  opacity: 0.4;
  position: absolute; /* Para permitir movimiento en el espacio */
  animation: watermarkMove 120s infinite ease-in-out; /* Duración y repetición */
  display: flex;
  flex-direction: column;
  align-items: center;
}


@media(hover: hover) and (pointer: fine) {
  .video-player-container:hover .top-navigation, .video-player-container:hover .bottom-navigation {
    transform: translateY(0) !important;
  }
  .burger-container, #fullscreen-btn {
    display: none;
  }
}

@media(any-pointer: coarse) or (hover: none) {
  .burger-container, #fullscreen-btn {
    display: flex;
  }
  .burger-container.active {
    transform: translateY(0) !important;
    transition: 0.2s ease-in-out;
  }
  .burger-container.active .burger-trigger {
    width: 46px;
    height: 46px;
    cursor: pointer;
    z-index: 6;
    position: relative;
    align-content: bottom !important; 
  }
  .burger-container .burger-trigger span {
    position: absolute;
    left: calc(14px / 2);
    bottom: 5px;
  }
  .video-player-container:hover .bottom-navigation {
    transform: translateY(0) !important;
  }
  .video-player-container.active .top-navigation {
    transform: translateY(0) !important;
  }
}
/* .video-player-container:hover .fav-button-container {
   bottom: 8% !important;
} */
.video-player-container .top-navigation, .video-player-container .bottom-navigation {
  width: 100%;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  transition: 0.2s ease-in-out;
}
.video-player-container .top-navigation {
  transform: translateY(-100%);
  top: 0;
}
.video-player-container .top-navigation .actions-container {
  gap: 16px;
}
.video-player-container .top-navigation .actions-container *:hover {
  cursor: pointer;
  color: #d9d9d9;
}
.video-player-container .top-navigation .actions-container img {
  border-radius: 100px;
  width: 36px;
  background-color: #F9F9F9;
}
.video-player-container .top-navigation .actions-container img:hover {
  background-color: #d9d9d9;
}
.video-player-container .top-navigation .video-title {
  width: auto;
  max-width: 520px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.video-player-container .bottom-navigation {
  bottom: 0;
  gap: 12px;
  white-space: nowrap;
  transform: translateY(100%);
}
.video-player-container .bottom-navigation *:hover {
  cursor: pointer;
  color: #d9d9d9;
}
.video-player-container .bottom-navigation .video-line {
  border-radius: 100px;
  background-color: #AFAFAF;
  height: 8px;
  width: 100%;
}
.video-player-container .fav-button-container {
  position: absolute;
  width: fit-content;
  bottom: 96px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 58px;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #3b3b3b;
  border-radius: 10px;
  transition: all ease-in;
}
.video-player-container .fav-extended {
  position: absolute;
  top: 120px;
  left: 40px;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #3b3b3b;
  border-radius: 10px;
  gap: 12px;
}
.favorite-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 100px;
}
.border-dark {
  border-color: #3b3b3b;
}
.video-player-container .fav-button-container .favorite-tag:hover {
  cursor: pointer;
}
.video-player-container .side-tags-container, .video-player-container .side-tags-container-description {
  background-color: rgba(0, 0, 0, 0.8);
  margin-left: auto;
  width: 25% !important;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.video-player-container .side-tags-container .video-tag-preview, .video-player-container .side-tags-container-description .video-tag-preview {
  margin: 16px 0;
  background-color: var(--vtg-color);
  height: 30%;
  border: solid transparent 1px;
}
.video-player-container .side-tags-container .video-tag-preview:hover, .video-player-container .side-tags-container-description .video-tag-preview:hover {
  border-color: white;
}
.ui-elements-container {
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ui-elements-container * {
  position: relative;
  z-index: 1;
}
.fake-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #b9b9b9;
  text-align: center;
  padding-top: 6px;
  cursor: pointer;
}
.video {
  position: absolute;
  z-index: 0;
}

.video-with-poster {
  object-fit: none;
  background-color: white !important;
}

input[type=checkbox] {
  cursor: pointer;
}

.resizable-with-corner {
  position: relative;
}

.resizable-with-corner::after {
  content: '';
  position: absolute;
  width: 26px;
  height: 26px;
  background-image:url("/public/assets/imgs/resize.png");
  background-size: cover;
  bottom: 0;
  right: 0;
}

.gap-0 { gap: 0}
.gap-1 { gap: .25rem}
.gap-2 { gap: .5rem}
.gap-3 { gap: 1rem}
.gap-4 { gap: 1.5rem}
.gap-5 { gap: 3rem}

.profile-tab-items {
  list-style: none;
}
.profile-tab-items li {
  padding: 6px 12px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  color: #646464;
}
.profile-tab-items li:hover {
  color: #1A1A1A;
}
.profile-tab-items .active {
  color: #1A1A1A;
  border-bottom-color: var(--vtg-color);
}

.container-height-auto {
  min-height: 100vh;
  height: 100%;
}

.nabModalBackground {
  background-color: rgba(0, 0, 0, 0.35);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.vjs-big-play-button {
  line-height: 3.5em !important;
}

.vjs-button > .vjs-icon-placeholder:before {
  line-height: 0.1em !important;
}

.vjs-load-progress {
  width: 100% !important;
}

.vjs-big-play-button > .vjs-icon-placeholder::before {
  top: -22px !important;
  left: -14px !important;
}

.vjs-control-bar {
  position: fixed !important;
}

.nab-marker {
  transform: perspective(40px) rotateX(20deg) rotateZ(-45deg);
  transform-origin: 50% 50%;
  border-radius: 50% 50% 50% 0;
  padding: 0 3px 3px 0;
  width: 30px;
  height: 30px;
  position: absolute;
}

.nab-marker-icon {
  z-index: 3;
  position: relative;
}

.nab-marker-icon:not(.material-icons-round) {
  font-weight: 800;
  font-size: 16px;
}

.nab-marker-fake {
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  z-index: 2;
}

.nab-marker-fake:after {
  content: '';
  width: 0px;
  height: 0px;
  bottom: -18px;
  border: 10px solid transparent;
  border-top: 12px solid;
  border-top-color: inherit !important;
  position: absolute;
  z-index: 1;
}

.modal-content {
  border-radius: 12px !important;
}

.modal-header {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.modal-title {
  text-align: center;
}

.modal-body:not(.no-scroll) {
  overflow-y: scroll;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.point-sm {
  height:16px;
  width:16px;
  border-radius:50%;
}

.cursor-pointer {
  cursor: pointer !important;
}

.opacity-less {
  opacity: 0.60;
}

.nab-hero {
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover
}

.extra-rounded {
  border-radius: 12px !important;
}

.extra-rounded-top {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.extra-rounded-bottom {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.profile-image {
  border-radius: 50%;
  border: solid 3px var(--vtg-color-dark);
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.vtg-spinner {
  border: .3em solid var(--vtg-color) !important;
  border-right-color: transparent !important;
}

label::not(.wrap){
  white-space: nowrap;
}

.nabModal {
  height: auto;
  background-color: transparent !important;
}

.nab-circle-btn-big {
  width:60px !important;
  height:60px !important;
  cursor: pointer;
}

.material-icons-round {
  cursor: inherit;
}

.material-symbols-outlined {
  cursor: inherit;
}

div .nab-circle-btn {
  width:36px;
  height:36px;
  border-radius:50%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: #a6a6a6;
  cursor: pointer;
}

div .nab-circle-btn > span{
  width: 100%;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center !important;
  color: white;
}

div .nab-circle-btn:hover {
  background-color: #3d3d3d;
}

.btn-xs {
  line-height: 0 !important;
  padding: .2rem .4rem !important;
  font-size: .8rem !important;
  border-radius: .2rem !important;
  min-height: 28px;
}

.border-vitag {
  border: solid 2px var(--vtg-color);
}

.bg-transparent {
  background-color: transparent !important;
}

.nab-boxing {
  box-sizing: border-box;
  border: solid 1px white;
  box-shadow: 0 0 6px;
}

.ctx-selector-menu {
  /* this fixes dropdown attachment issue */
 /*position: fixed !important;*/
}

#markersSideBar {
  z-index:555;
  position:fixed;
  top:0;
  right:-280px;
  width:280px;
  height:calc(100vh - 80px);
  transform: translateX(280px);
  transition: ease 0.4s;
  margin-top:80px;
}

#markersSideBar.active {
  transform: translateX(-280px);
}

.sidebar-panel {
  display: flex;
  flex-direction: column;
  background-color:#efefef;
  height: 100%;
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}

.marker-summary-op-0 {
  opacity: 1;
}

.marker-summary-op-1 {
  opacity: 0.55;
}

.marker-summary-op-2 {
  opacity: 0.9;
}

.marker-info {
  border-top-left-radius:0 !important;
  border-top-right-radius:0 !important;
}

span.material-icons-round {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.ck-read-only {
  border: none !important;
  padding: 0 !important;
}

.ck.ck-balloon-panel:not(.ck-toolbar-container) {
	z-index: 10001;
}

.ck-editor__editable {
  min-height: 200px;
}

.profile-container {
  position: relative;
}

.profile-container img, .profile-container div {
  position: absolute;
}

.owner-indicator {
  width: 16px;
  height: 16px;
  border: solid 1px #8d8d8d;
  border-radius: 50%;
  background-color: rgb(64, 201, 255);
  bottom: -3px;
  right: -3px;
}

.border-soft {
  border: solid 1px #7d7d7d;
}

/*.page-item.page {
  border-right: solid 1px #dddddd !important;
}*/

.without-border {
  border: none !important;
  box-shadow: none !important;
}

.without-border:focus-visible, .without-border:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.page-link {
  height: 40px !important;
  width: 64px !important;
  border: none !important;
  border-radius: 0 !important;
}

.page-link:not(.active, .navigate-link) {
  color: var(--vtg-color) !important;
}

.page-link:focus {
  box-shadow: none;
}

.page-link.active {
  background-color: var(--vtg-color-dark) !important;
  color: white !important;
  font-weight: bolder;
}

.navigate-link {
  color: var(--vtg-color-dark) !important;
  height: 42px !important;
  width: 48px !important;
}

.btn-circle {
  height: 42px;
  width: 42px;
  border-radius: 50% !important;
  align-items: center;
  vertical-align: middle;
}

.btn-placeholder {
  height: 0px;
  width: 42px;
}

.btn-circle > .material-icons-round {
  display: block;
  font-size: 36px;
}

.navigate-link.disabled {
  background-color: #dadada !important;
  height: 42px !important;
  width: 48px !important;
  color: white !important;
}

.navigate-link.next {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.navigate-link.prev {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.custom-toast {
  position:absolute;
  bottom:32px;
  right:32px;
}

.label-required::after {
  content: "*";
  color: red;
}

.show-only-first { display: inline; }
.show-only-first + .show-only-first { display: none; }

.modal-content.semi-transparent {
  background-color: transparent;
  & label:not(.fake-btn), & .nav-item.nav-link:not(.active), & .modal-title {
    color: white !important;
  }
}

.modal-header {
  background-color: #dadada;
}

.icon-indicator:hover + .pill-animated,
.pill-animated:hover {
    max-width: 160px;
    margin: auto 0.75rem auto 0.25rem;
    transition: all 0.4s ease-in-out;
}
.pill-animated {
    max-width: 0px;
    margin: 0;
    overflow-x: hidden;
    transition: all 0.4s ease-in-out;
}


.beta-indicator {
  position: fixed;
  right: -50px;
  bottom: 15px;
  width: 180px;
  font-size: 20px;
  background-color: #fb8c00;
  text-align: center;
  color: white;
  font-weight: bold;
  transform: rotate(-45deg);
  text-decoration: none !important;
  cursor: pointer !important;
  z-index: 1038;
}

.rotate-y {
  transform: rotateY(180deg);
}

.favbar-fullscreen {
  width: 40%;
  left: 18px;
  right: auto;
}

.favbar-fullscreen.to-right {
  left: auto;
  right: 18px;
}

@media (min-width: 576px) { 
  .favbar-fullscreen { width:60%; }
}
@media (min-width: 768px) { 
  .favbar-fullscreen { width:50%; }
}
@media (min-width: 992px) { 
  .favbar-fullscreen { width:40%; }
}
@media (max-width: 767px) { 
  .mt-lg-2 { margin-top: 0.5rem !important; }
}


.beta-indicator:hover {
  color: var(--vtg-color-dark);
  text-decoration: none;
  cursor: pointer;
}

.context-image {
  height:32px !important;
  width:32px !important;
  object-fit:contain;
  background-color:white;
  border-radius:100px;
  border: solid 1px #ededed;
}


.toast-container {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1em;
  z-index: 999;
}

.toast-normal .toast-header {
  background-color: #ffffff;
  color: #0d0d0d;
}

.toast-normal .toast-body {
  background-color: #d8d8d8;
  color: #1A1A1A;
}

.toast-success .toast-header {
  background-color: #1ba82e;
  color: #f3f3f3;
}

.toast-success .toast-body {
  background-color: #d8ffdd;
  color: #1A1A1A;
}

.toast-error .toast-header {
  background-color: #df2424;
  color: #f3f3f3;
}

.toast-error .toast-body {
  background-color: #ffd8d8;
  color: #1A1A1A;
}

.toast-warning .toast-header {
  background-color: #db9107;
  color: #1A1A1A;
}

.toast-warning .toast-body {
  background-color: #fce5c7;
  color: #1A1A1A;
}

.toast-info .toast-header {
  background-color: #0c5460;
  color: #f3f3f3;
  .close {
    font-weight: 400;
    color: #fff;
  }
}

.btn-white-text .inverse {
  color: white;
  line-height: 1;
  background-color: var(--vtg-color-dark);
  padding: 0.2em 0.4em;
  margin-top: -0.2em;
}

.toast-info .toast-body {
  background-color: #d1ecf1;
  color: #0c5460;
  .alert-link {
    color: #0c5460;
  }
}

span {
  cursor: default;
}

button span {
  cursor: inherit;
}

.dropdown-item:active {
  background-color: var(--vtg-color) !important;
  color: white !important;
}

.fw-bold {
  font-weight: 800 !important;
}

.mini-tab {
  background-color:#ededed;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  line-height: 0;
  margin: auto 1px;
  cursor: pointer;
}

.mini-tab.active {
  background-color:#a22a26;
  color: white;
  cursor: default;
} 

.skeleton-video span:nth-child(1) {
  background-color: var(--skeleton-color);
  display: block;
  width: 80%;
  height: 120px;
  margin-bottom: 0.6em;
}

.skeleton-video span:nth-child(2) {
  background-color: var(--skeleton-color);
  display: block;
  border-radius: 6px;
  width: 70%;
  margin-bottom: 0.4em;
  height: 16px;
}

.skeleton-video span:nth-child(3) {
  background-color: var(--skeleton-color);
  display: block;
  border-radius: 6px;
  width: 50%;
  height: 12px;
}

.skeleton-header {
  width: 100%;
  background-color: var(--vtg-color);
}

.skeleton-white-line {
  height: 12px;
  background-color: #e0e0e08c;
  border-radius: 16px;
}

.skeleton-white {
  color:#e0e0e08c;
}

.skeleton-tab {
  height: 64px;
  border-bottom: solid 1px #ededed;
  width: 100%;
}

.skeleton-tab-item-text {
  border-radius: 10px;
  background-color: var(--skeleton-color);
  display: inline-block;
  height: 24px;
  width: 120px;
}

.skeleton-tab-item.active {
  border-bottom: solid 3px var(--vtg-color);
}

@-webkit-keyframes color-transition {
  0% { opacity: 0.60; }
  50% { opacity: 0.18; }
  100% { opacity: 0.60; }
}

@keyframes color-transition {
  0% { opacity: 0.60; }
  50% { opacity: 0.18; }
  100% { opacity: 0.60; }
}

.animated-background {
  -webkit-animation: color-transition 2s ease-out infinite;
  -moz-animation: color-transition 2s ease-out infinite;
  -o-animation: color-transition 2s ease-out infinite;
  animation: color-transition 2s ease-out infinite;
}

.status-presentation {
  box-sizing: border-box;
  appearance: none;
  border: none;
  padding: var(--ds-space-025, 2px) 0;
  vertical-align: text-bottom;
  max-width: 100%;
  padding-inline: var(--ds-space-050, 15px);
  display: inline-flex;
  border-radius: var(--ds-border-radius, 10px);
  block-size: min-content;
  position: static;
  overflow: hidden;
  font-size: 0.9rem;
}

.status-presentation.SS_Active {
  color: var(--ss-text-active);
  background-color: var(--ss-background-active);
}

.status-presentation.SS_Paused {
  color: var(--ss-text-paused);
  background-color: var(--ss-background-paused);
}

.status-presentation.SS_Expired, .status-presentation.SS_Deleted {
  color: var(--ss-text-expired);
  background-color: var(--ss-background-expired);
}

.status-presentation.SS_Inactive {
  color: var(--ss-text-inactive);
  background-color: var(--ss-background-inactive);
}


.subscription-date {
  padding: var(--ds-space-025, 5px);
}

.drowdown-context {
  max-height: 220px; 
  overflow: auto
}

.invalid {
  border-color: #f5acaf !important;
  box-shadow: 0 0 0 .2rem rgba(234, 89, 95, .25) !important;
}

.alertIcon {
  position: absolute;
  top: 45px;
  left: 5px;
  z-index: 5000;
  cursor: pointer;
  color: orange;
  background-color: #000;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  vertical-align: top !important;
}

.alertWarning {
  cursor: pointer !important;
  position: absolute !important;
  top: 50px;
  left: 44px;
  z-index: 4000;
}

.circle-badge {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.50);
  border-radius: 50%;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
}


.input-group-interval {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.input-group-prepend-interval,
.input-group-append-interval {
  display: flex;
}

.input-group-prepend-interval button,
.input-group-append-interval button {
  margin: 0 2px;

}

.input-group-interval input {
  flex: 1;
  padding: 5px;

  &.form-control:disabled, &.form-control[readonly] {
    background-color: #f9fafb;
  }
}

.input-group-interval>.input-group-prepend-interval>button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend-interval button:first-child {
  border-radius: .25rem 0 0 .25rem;
}

.input-group-append-interval button:last-child {
  border-radius: 0 .25rem .25rem 0;
}

.input-group-prepend-interval button,
.input-group-append-interval button {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  font-size: 1rem;
  padding: 0.375rem 0.35rem;
  align-items: center;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.5;
  height: calc(1.5em + .75rem + 2px);
  width: calc(1.5em + .75rem + 11px);
  &:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
}

.input-group-interval>.form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-interval>.form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group .input-group-prepend button, .input-group .input-group-append button {
  &:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
}

.sidebar-content {
  scroll-behavior: smooth;
}

.modal-backdrop+.modal+.modal-backdrop {
  z-index: 1060 !important;
}

.modal-backdrop~.modal-backdrop {
  z-index: 1060 !important;
}

.modal-backdrop+.modal+.modal-backdrop+.modal+.modal-backdrop {
  z-index: 1080 !important;
}

.modal-backdrop~.modal-backdrop~.modal-backdrop {
  z-index: 1080 !important;
}

.profile-pill {
  position: relative;
  display: inline-block;
  text-align: center;
}

.badge-icon {
  bottom: -6px;
  padding: 0px 6px;
  border-radius: 10px;
  font-size: 10px;
  line-height: 1.4;
  cursor: pointer;
}

.badge-main {
  bottom: -8px;
  padding: 2px 14px;
  border-radius: 18px;
  font-size: 18px;
}

.badge-icon, .badge-main {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #c1a154;
  border: 1px solid var(--ss-text-inactive);
  color: white;
  font-weight: bold;
}

.mirrored-icon {
  transform: scaleX(-1);
  display: inline-block;
}

.tier-qty {
  white-space: nowrap;
  padding: 6px 12px;
  margin: 6px;
  border-radius: 50px;
  color: #6c757d;
}

.tier-qty-container .active, .attendees-qty-container .active:hover {
  background-color: #343a40 !important;
  color: #fff !important;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.75s forwards;
}
.fade-out {
  opacity: 1;
  animation: fadeOut 0.75s forwards;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.card-brand {
  max-width: 24px;
  max-height: 24px;
}