body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.loading-indicator {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1em;
}

.cartoon-text {
  -webkit-text-stroke: 4px white;
  color: black;
}

.btn.btn-white-text {
  color: white;
  background-color: transparent !important;
}

.btn.btn-white-text:hover {
  color: #000000 !important;
  background-color: #ededed !important;
}

.btn.btn-white-text.fav-disable > span {
  color: #3f5162 !important;
}

/* Works on Firefox */
* {
  scrollbar-width: initial;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: rgb(210, 210, 209);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(98, 98, 98);
  border-radius: 20px;
  border: 1px solid rgb(210, 210, 209);
}
