.vimeo-progress-bar {
    cursor: pointer;
    background-color: #d8d8d8;
    border:solid 1px #fd838344;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    height: 6px;
}

.vimeo-progress-bar:hover {
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    height: 10px;
}

.le-progress {
    cursor: pointer;
    background-color: var(--vtg-color);
}

.time-indicator {
    background-color: white;
    position: absolute;
    bottom: 46px;
    padding: 4px 8px;
    width: 64px;
    font-size: small;
    text-align: center;
    z-index: 9999;
}
input[type=range]{
accent-color: var(--vtg-color);
}

input[type=range]::-webkit-slider-runnable-track {
    cursor: pointer;
    /*background: var(--vtg-color);*/
}

input[type=range]:focus::-webkit-slider-runnable-track {
    /*background: var(--vtg-color);*/
}

input[type=range]::-moz-range-track {
    cursor: pointer;
    background: white;
}

input[type=range]::-moz-range-progress {
    cursor: pointer;
    background: var(--vtg-color);
}

input[type=range]::-ms-track {
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: var(--vtg-color);
}
input[type=range]:focus::-ms-fill-lower {
    background: var(--vtg-color);
}
input[type=range]::-ms-fill-upper {
    background: var(--vtg-color);
}
input[type=range]:focus::-ms-fill-upper {
    background: var(--vtg-color);
}
